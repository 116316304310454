import * as React from "react";
import "@aws-amplify/ui/dist/style.css";
import Layout from "components/layouts/Common";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Article from "components/pages/front/Article";
import htmlToText from "html-to-text";
import { getCfUrl } from "settings/storageSettings";
type Props = {
  pageContext: any;
};

export default function App(props: Props) {
  const { pageContext } = props;
  const { article, categories, footers, categoryArticles } = pageContext;

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const description = htmlToText.fromString(article.description, {
    ignoreImage: true,
    ignoreHref: true,
  });

  return (
    <>
      {article && (
        <GatsbySeo
          title={`${article.title}　舵オンライン │ 船遊びの情報サイト`}
          description={description}
          openGraph={{
            url: `https://www.kazi-online.com/articles/${article.slug}`,
            title: `${article.title}　舵オンライン │ 船遊びの情報サイト`,
            description,
            type: "article",
            images: [
              {
                url: article.mainImage ? getCfUrl(article.mainImage.url) : "",
                alt: article.title,
              },
            ],
          }}
        />
      )}
      <Layout footers={footers}>
        <Article
          article={article}
          categories={categories}
          categoryArticles={categoryArticles}
        />
      </Layout>
    </>
  );
}
